import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../components/layout';

const HymotsSolutions = (props) => {

    const handleClick = (id) => {
        navigate('/');
        var checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
                clearInterval(checkExist);
            }
        }, 100);
    }

    return (
        <Layout location={props.location}>
            <div className='hymots'>
                <div className='hymots__top'>
                    <div className='hymots__top__content'>
                        <h1 className='hymots__top__content__title'>hymots<sup><small>®</small></sup> Solutions</h1>
                        <p className='hymots__top__content__detail'>
                            Our hymots® based solutions targeting defense enables simulations for operational, strategic and tactical training.
                            It is a multidomain approach which covers all military branches including army, navy and air force as well as space and cyber.
                            The simulations can be configured and orchestrated using a Drag and Drop graphical user interface. Both aggregate levels and
                            hybrid environments are supported. A diverse set of ready and configurable components/modules are available. <br />
                            <br />
                            hymots® have three main operating modes: In the Computer Assisted eXercises (CAX) mode hymots can be used in time steps or interactively and
                            computes how the virtual theater is shaped according to the expected results of the actions taken by the CAX audience. In the Planning mode
                            hymots® is used as a static simulation system and calculates the capabilities needed to achieve the selected objectives in a given scenario.
                            Experimentation mode, uses the same models as in CAX mode with the addition of a confidence interval instead of an expected result.<br />
                            <br />
                            Solutions can be deployed and accessed remotely in an MSaaS configuration supported by high performance simulations running in our datacenter.
                            We also offer on-premise installations and licensing models including local, offline and even mobile installations.  <br />
                            <br />
                            <u onClick={() => navigate('/contact')}>Learn more</u> about our modeling and simulation services such as training, model development and orchestration 
                            of domain specific simulation or <u onClick={() => navigate('/contact')}>get in touch</u> to discuss your needs and any special requirements.
                    </p>
                    </div>
                    <img src='/images/hymots-2.png' className='hymots__top__image' alt='hymots' />
                </div>
            </div>
        </Layout>
    );
}

export default HymotsSolutions;